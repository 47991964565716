<template>
  <div class="container">
    <img class="avatar" :src="avatar" alt="">
    <span class="text">恭喜{{ nickname }}获得</span>
    <span class="text">{{ productName }}！</span>
    <img class="cover" :src="productImage" alt="">
    <img class="qrcode" :src="qrcode" alt="">
  </div>
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  data() {
    return {
      qrcode: "",
    }
  },
  created() {
    const {
      avatar,
      nickname,
      productName,
      productImage,
      clubId,
    } = this.$route.query;
    this.avatar = avatar;
    this.nickname = nickname;
    this.productName = productName;
    this.productImage = productImage;
    this.clubId = clubId;
    this.encodeCumenId(clubId);
  },
  methods: {
    /** 编码 ID */ 
    encodeCumenId(id) {
      $fetch
        .post("EncodeCumenId", { id })
        .then((res) => {
          this.getWechatCode(res.id);
        });
    },
    /** 生成微信二维码 */ 
    getWechatCode(scene) {
      const _ = this;
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/goods/pages/club/index',
          scene,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1.205;

.container {
  @include column(flex-start, center);
  position: relative;
  width: 100vw;
  height: 153.05vw;
  background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0189b56a-ccb9-f322-9d14-7d2c9c966e55?x-oss-process=style/origin") no-repeat center top / cover;

  > .avatar {
    position: absolute;
    top: px2rem(60 * $multipleCount);
    width: px2rem(112 * $multipleCount);
    height: px2rem(112 * $multipleCount);
    border-radius: 50%;
    border: px2rem(4 * $multipleCount) solid #fff;
    background-color: #fff;
  }

  > .text {
    @include ellipsis;
    position: absolute;
    top: px2rem(196 * $multipleCount);
    width: calc(100vw - px2rem(24 * $multipleCount));
    height: px2rem(64 * $multipleCount);
    font-size: px2rem(48 * $multipleCount);
    font-weight: 900;
    line-height: px2rem(64 * $multipleCount);
    text-align: center;
    font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
    background: linear-gradient(90deg, #07E6FF 0%, #72FF07 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &:nth-of-type(2) {
      top: px2rem(260 * $multipleCount);
    }
  }

  > .cover {
    position: absolute;
    top: px2rem(376 * $multipleCount);
    width: px2rem(208 * $multipleCount);
    height: px2rem(208 * $multipleCount);
    border-radius: px2rem(8 * $multipleCount);
  }

  > .qrcode {
    position: absolute;
    top: px2rem(823 * $multipleCount);
    right: px2rem(31 * $multipleCount);
    width: px2rem(100 * $multipleCount);
    height: px2rem(100 * $multipleCount);
    border-radius: 50%;
  }
}
</style>
